// colors
$primary: #E23D26;

$secondary-light: #A9BFF0;
$secondary: #1D306D;
$secondary-dark: #15245D;

$white: #FFFFFF;
$black: #000000;
$gray: #CACACA;
$gray-dark: #6E6E6E;
$gray-light: #F7F7F7;

$info: #1473E6;
$success: #2D9D78;
$danger: #F64E60;
$warning: #FFB401;

$colors: (
  "primary": $primary,

  "secondary-light": $secondary-light,
  "secondary": $secondary,
  "secondary-dark": $secondary-dark,

  "white": $white,
  "black": $black,
  "gray": $gray,
  "gray-dark": $gray-dark,
  "gray-light": $gray-light,

  "info": $info,
  "success": $success,
  "danger": $danger,
  "warning": $warning,
) !default;

// metrics
$minimal: 2px;
$smallest: 4px;
$smaller: 8px;
$small: 10px;
$medium: 12px;
$normal: 14px;
$large: 16px;
$larger: 24px;
$largest: 32px;
$huge: 40px;
$huger: 52px;
$hugest: 60px;

$metrics: (
  "minimal": $minimal,
  "smallest": $smallest,
  "smaller": $smaller,
  "small": $small,
  "medium": $medium,
  "normal": $normal,
  "large": $large,
  "larger": $larger,
  "largest": $largest,
  "huge": $huge,
  "huger": $huger,
  "hugest": $hugest
) !default;
