@import '~/styles/variables';

.terms-layout {
  background-color: $gray-light;
  min-height: 100vh;
  min-width: 100vw;

  header {
    position: fixed;
    width: 100%;
    padding-left: 120px;
    background-color: $secondary;
    z-index: 3;

    .header-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between !important;
      align-items: center;
      padding: $medium;
      color: $white;
    }

    .logo {
      height: 40px;
    }
  }

  .terms-content {
    position: relative;
    padding-top: 98px;
    padding-bottom: $huge;

    pre {
      white-space: pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: -pre-wrap;
      white-space: -o-pre-wrap;
      word-wrap: break-word;
    }
  }
}
