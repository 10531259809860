@import '~/styles/variables';

.absence-detail {
  .user {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $medium;

    .user-detail {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .user-avatar {
      border-radius: 100%;
      height: 80px;
      width: 80px;
      background-image: linear-gradient($primary, $primary),
        linear-gradient($white, $white),
        linear-gradient($secondary, $secondary);
      background-repeat: no-repeat;
      background-size: 49% 49%, 51% 51%, 100% 100%;
      background-position: left top bottom, 0 0 0;
      transform: rotate(-90deg);

      img {
        border-radius: 100%;
        width: 76px;
        height: 76px;
        margin: 2px;
        border: 2px solid $white;
        background-color: $white;
        transform: rotate(90deg);
        object-fit: cover;
      }
    }

    .user-name {
      margin: 0 0 0 $medium;
      font-weight: bold;
    }

    .user-action {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $gray-dark;
    }

  }

  .docs {
    padding: $small $medium;

    .doc-item {
      padding: $larger 0;
      border-bottom: 1px solid #CCCCCC;

      &:first-child {
        padding-top: 0 !important;
      }

      &:last-child {
        border: none !important;
      }
    }

    .doc-label {
      display: flex;
      align-items: center;
      color: $primary;
      font-size: $large;
      font-weight: bold;
      width: 60px;
      margin-bottom: $smaller;
    }

    .detail {
      flex-direction: column;
      align-items: center;
      color: $black;
      font-size: $normal;

      p {
        margin: 0 0 $minimal 0;
      }
    }

    .btn {
      margin-top: $large;
    }
  }
}
