@use "sass:math";

@import '~/styles/functions';
@import '~/styles/variables';

.btn {
  border-radius: $larger !important;
  font-weight: bold !important;
  transition: all .3s !important;
  align-items: center;
  justify-content: center;

  @each $color, $value in $colors {
    &.button-color-#{$color} {
      background-color: $value !important;
      border-color: $value !important;
      border-width: $minimal !important;

      $with-light-text: ('primary', 'secondary', 'secondary-dark', 'black', 'gray-dark', 'info', 'success', 'danger');
      @if contains($with-light-text, $color) {
        color: $white !important;
      } @else {
        color: $black !important;
      }

      &:hover {
        background-color: rgba($value, .6) !important;
        border-color: rgba($value, .6) !important;
      }
    }

    &.button-outline-color-#{$color} {
      background-color: transparent !important;
      border-color: $value !important;
      border-width: $minimal !important;
      color: $value !important;

      &:hover {
        opacity: .6 !important;
      }
    }
  }

  @each $size, $value in $metrics {
    &.button-size-#{$size} {
      font-size: $value !important;
      padding: math.div($value, 1.2);
    }
  }
}
