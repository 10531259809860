@import '~/styles/variables';

.report-detail {
  .user {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $medium;

    .user-detail {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .user-avatar {
      border-radius: 100%;
      height: 80px;
      width: 80px;
      background-image: linear-gradient($primary, $primary),
        linear-gradient($white, $white),
        linear-gradient($secondary, $secondary);
      background-repeat: no-repeat;
      background-size: 49% 49%, 51% 51%, 100% 100%;
      background-position: left top bottom, 0 0 0;
      transform: rotate(-90deg);

      img {
        border-radius: 100%;
        width: 76px;
        height: 76px;
        margin: 2px;
        border: 2px solid $white;
        background-color: $white;
        transform: rotate(90deg);
        object-fit: cover;
      }
    }

    .user-name {
      margin: 0 0 0 $medium;
      font-weight: bold;
    }

    .user-action {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $gray-dark;
    }
  }

  .report-page {
    padding: 24px 0;

    .report-interval-title {
      color: $primary;
      font-size: $large;
      font-weight: bold;
    }

    .report-interval-value {
      color: $black;
      font-size: $large;
    }
  }

  .calendar {
    .calendar-day {
      border-top: 2px solid #CACACA;
    }

    .calendar-icon {
      height: 30px;
      pointer-events: none;
      margin-bottom: $smaller;
    }

    .calendar-icon-label {
      font-size: $small;
      color: $secondary;
      margin: $smallest 0;
    }

    .calendar-title {
      font-size: $large;
      font-weight: 600;
      color: $secondary;
    }

    .calendar-date {
      font-size: $large;
      font-weight: 600;
      color: $primary;
      text-transform: capitalize;
    }
  }

  .card-report {
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
    border-radius: $small;
    width: 100%;
    padding: $large;

    h1,
    h4 {
      font-weight: bold;
    }
  }

  .journey-item {
    border-bottom: 2px solid #CACACA;

    &:last-child {
      border-bottom: none !important;
    }

    .journey-time {
      padding: 8px 0 !important;
      margin: 0 !important;
      border-bottom: 1px solid #CACACA;

      &:last-child {
        border-bottom: none !important;
      }

      .journey-time-separator {
        color: $gray;
        margin: 0 6px;
      }
    }
  }

  .empty-label {
    margin: 16px 0;
    font-size: 20px;
    font-weight: 600;
    color: $primary;
    text-align: center;
  }

  .w20 {
    padding: 0;
    width: 52px !important;
    max-width: 52px;
    flex: none;
    border-left: 1px solid #CACACA;
  }

  .w20p {
    width: 20% !important;
  }

  .subtotal-title {
    color: $primary;
    font-size: $medium;
    font-weight: bold;
  }

  .subtotal-value {
    color: $secondary;
    font-size: 20px;
    font-weight: bold;
  }

  .total-title {
    color: $primary;
    font-size: $large;
    font-weight: bold;
  }

  .total-value {
    color: $secondary;
    font-size: $largest;
    font-weight: bold;
  }
}

@page {
  size: auto;
  margin: 15mm;
}

@media print {
  html,
  body {
    max-width: calc(100% - 30mm);
  }

  .report-interval-title {
    margin-right: 30mm;
    color: $primary;
    font-size: $large;
    font-weight: bold;
  }

  .report-interval-value {
    margin-right: 30mm;
    color: $black;
    font-size: $large;
  }

  .card-report {
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: $small;
    width: 100%;
    max-width: calc(100% - 30mm);
    padding: $large;
  }

  .empty-label {
    margin: 16px 0;
    font-size: 20px;
    font-weight: 600;
    color: $primary;
    text-align: center;
  }

  .calendar-day {
    border-top: 2px solid #CACACA;
  }

  .calendar-icon {
    height: 30px;
    pointer-events: none;
    margin-bottom: $smaller;
  }

  .calendar-icon-label {
    font-size: $small;
    color: $secondary;
    margin: $smallest 0;
  }

  .calendar-title {
    font-size: $large;
    font-weight: 600;
    color: $secondary;
  }

  .calendar-date {
    font-size: $large;
    font-weight: 600;
    color: $primary;
    text-transform: capitalize;
  }

  .journey-item {
    border-bottom: 2px solid #CACACA;
  }

  .journey-item:last-child {
    border-bottom: none !important;
  }

  .journey-time {
    padding: 8px 0 !important;
    margin: 0 !important;
    border-bottom: 1px solid #CACACA;
  }

  .journey-time:last-child {
    border-bottom: none !important;
  }

  .journey-time-separator {
    color: $gray;
    margin: 0 6px;
  }

  .w20 {
    padding: 0;
    max-width: 50px !important;
    border-left: 1px solid #CACACA;
  }

  .subtotal-title {
    color: $primary;
    font-size: $large;
    font-weight: bold;
  }

  .subtotal-value {
    color: $secondary;
    font-size: 20px;
    font-weight: bold;
  }

  .total-title {
    color: $primary;
    font-size: $larger;
    font-weight: bold;
  }

  .total-value {
    color: $secondary;
    font-size: $largest;
    font-weight: bold;
  }
}
