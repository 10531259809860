@import '~/styles/variables';

.dashboard-map {
  position: relative;
  width: 100%;
  height: 600px;
  margin-top: $larger;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);

  .detail {
    position: absolute;
    left: $large;
    bottom: $huge;
    padding: $large;
    border-radius: $small;
    width: 80%;
    z-index: 99;
    background-color: $white;

    .user-detail {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .user-avatar {
      border-radius: 100%;
      height: 50px;
      width: 50px;
      background-image: linear-gradient($primary, $primary),
                        linear-gradient($white, $white),
                        linear-gradient($secondary, $secondary);
      background-repeat: no-repeat;
      background-size: 49% 49%, 51% 51%, 100% 100%;
      background-position: left top bottom, 0 0 0;
      transform: rotate(-90deg);

      img{
        border-radius: 100%;
        width: 46px;
        height: 46px;
        margin: 2px;
        border: 2px solid $white;
        background-color: $white;
        transform: rotate(90deg);
        object-fit: cover;
      }
    }

    .user-name {
      margin: 0 0 0 $medium;
      font-weight: bold;
      font-size: $large;
    }

    .journey-total {
      text-align: right;

      h4 {
        font-size: $normal;
        color: $primary;
        margin: 0;
      }

      h3 {
        font-size: $larger;
        font-weight: bold;
        color: $secondary;
        margin: 0;
      }
    }
  }
}
