@import '~/styles/variables';

.pagination {
  display: flex;
  flex-direction: row;
  color: $gray-dark;
  font-weight: 500;
  margin-right: 15px;
  margin-bottom: 0;

  & li:not(.next, .previous) a {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & li:not(.next, .previous) {
    &.active {
      pointer-events: none;
      background-color: $info;
      width: 35px;
      height: 35px;
      color: $white;
      border-radius: 100%;
    }
  }

  & li.next {
    padding-left: 10px;
  }

  & li.previous {
    padding-right: 10px;
  }

  & li.next,
  & li.previous {
    display: flex;
    align-items: center;
  }

  & li.disabled {
    color: $gray;
  }
}
