@import '~/styles/variables';

.input-select {
  display: flex;
  flex-direction: column;
  padding: $small 0;

  &.input-dark {
    label {
      color: $secondary-light;
    }

    select {
      border: 1px solid $secondary-dark;
      background-color: $secondary-dark;
      color: $white;

      &::placeholder {
        color: $secondary-light;
      }
    }
  }

  &.input-light {
    label {
      color: $black;
    }

    select {
      border: 1px solid $gray !important;
      background-color: $white;
      color: $black;

      &::placeholder {
        color: $black;
      }

      &:disabled {
        background-color: $gray-light !important;
      }
    }
  }

  label {
    font-size: $medium;
    margin-bottom: $minimal;
  }

  select {
    border-radius: $smallest;
    padding: $smaller;
    font-size: $medium;
    margin: $minimal 0px;
    width: 100%;
  }

  .error-message {
    font-size: $medium;
    color: $danger;
  }
}
