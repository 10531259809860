@import '~/styles/variables';

.table {
  margin: $larger 0;

  thead {
    background-color: $secondary-dark;
    color: $white;
    font-weight: bold;
  }

  tbody {
    background-color: $white;
  }

  tr {
    font-size: $medium;
    transition: all .3s;

    &.row-hover {
      cursor: pointer;
    }

    &.row-hover:hover {
      background-color: $gray-light;
    }
  }

  th {
    padding: 15px 8px !important;
  }

  td {
    padding: 15px 8px !important;
  }

  th:first-child {
    border-radius: $small 0 0 0 !important;
  }

  th:last-child {
    border-radius: 0 $small 0 0 !important;
  }

  tr:last-child td:first-child {
    border-radius: 0 0 0 $small !important;
  }

  tr:last-child td:last-child {
    border-radius: 0 0 $small 0 !important;
  }

  tr:last-child {
    border: $gray-light;
  }

  .row-selected {
    background-color: rgba($secondary-light, .3);
  }
}

.empty-state {
  text-align: center;
  padding: 150px 0;
}
