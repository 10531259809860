@import '~/styles/variables.scss';

.icon {
  @each $color, $value in $colors {
    .fill-#{ $color } {
      fill: $value !important;
    }

    .stroke-#{ $color } {
      stroke: $value !important;
    }
  }

  @each $metric, $value in $metrics {
    .size-#{ $metric } {
      width: $value !important;
      height: $value !important;
    }
  }
}
