@import '~/styles/variables';

.card-chart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
  border-radius: $smaller;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  padding: $large;
  margin: $larger 0;
  height: 350px;

  .card-chart-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .card-title {
    margin-left: $smaller;
    font-size: $normal;
    font-weight: bold;
    color: $primary;
    padding: 0;
    margin: 0;
  }
}
