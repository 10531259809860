@import '~/styles/variables';

.main-layout {
  background-color: $gray-light;
  min-height: 100vh;
  min-width: 100vw;

  header {
    position: fixed;
    width: 100%;
    padding-left: 120px;
    background-color: $secondary;
    z-index: 3;

    .header-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between !important;
      align-items: center;
      padding: $medium;
      color: $white;
    }

    .logo {
      height: 40px;
    }

    .logout {
      padding: $medium $minimal $medium $large;
      font-size: $normal;
      font-weight: bold;
      cursor: pointer;
      transition: all .3s !important;

      &:hover {
        opacity: .6 !important;
      }
    }
  }

  .main-container {
    position: relative;
    display: flex;
    flex-direction: row;
    padding-left: 120px;
    color: $secondary;

    .sidemenu {
      overflow-y: auto;
      position: fixed;
      left: 0;
      background-color: $white;
      padding: $medium 0;
      margin-top: 68px;
      height: calc(100vh - 68px);
      width: 120px;
      z-index: 2;

      .user-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-bottom: $medium;

        .user-avatar {
          border-radius: 100%;
          height: 80px;
          width: 80px;
          background-image: linear-gradient($primary, $primary),
                            linear-gradient($white, $white),
                            linear-gradient($secondary, $secondary);
          background-repeat: no-repeat;
          background-size: 49% 49%, 51% 51%, 100% 100%;
          background-position: left top bottom, 0 0 0;
          transform: rotate(-90deg);

          img{
            border-radius: 100%;
            width: 76px;
            height: 76px;
            margin: 2px;
            border: 2px solid $white;
            background-color: $white;
            transform: rotate(90deg);
            object-fit: cover;
          }
        }


        .user-name {
          margin-top: $smaller;
          font-size: $normal;
          font-weight: bold;
        }
      }

      .menu-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: $small 0;
        border-left: 4px solid $white;
        transition: all .3s;

        &.selected {
          border-left: 4px solid $primary;
          pointer-events: none;

          .label {
            color: $primary;
          }

          svg {
            fill: $primary !important;
          }
        }

        .label {
          transition: all .3s;
          font-size: $medium;
        }

        svg {
          transition: all .3s;
          fill: $secondary !important;
        }

        &:hover {
          opacity: 1 !important;

          &.selected {
            border-left: 4px solid $primary;
          }

          .label {
            color: $primary;
          }

          svg {
            fill: $primary !important;
          }
        }
      }
    }

    .main-content {
      position: relative;
      margin-top: 98px;
      margin-bottom: $huge;
    }
  }
}
