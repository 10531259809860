@import '~/styles/variables';

.input {
  display: flex;
  flex-direction: column;
  padding: $small 0;

  &.input-dark {
    label {
      color: $secondary-light;
    }

    input, textarea {
      border: 1px solid $secondary-dark;
      background-color: $secondary-dark;
      color: $white;

      &::placeholder {
        color: $secondary-light;
      }
    }
  }

  &.input-light {
    label {
      color: $black;
    }

    input, textarea {
      border: 1px solid $gray !important;
      background-color: $white;
      color: $black;

      &::placeholder {
        color: $black;
      }

      &:disabled {
        background-color: $gray-light !important;
      }
    }
  }

  label {
    font-size: $medium;
    margin-bottom: $minimal;
  }

  .input-section {
    position: relative;
  }

  input, textarea {
    border-radius: $smallest;
    padding: $smaller;
    font-size: $medium;
    margin: $minimal 0px;
    width: 100%;
  }

  .input-icon {
    position: absolute;
    padding: $smallest $medium;
    top: $smallest;
    right: 1px;

    &.light {
      background-color: $white;
    }

    &.dark {
      background-color: $secondary-dark;
    }
  }

  .error-message {
    font-size: $medium;
    color: $danger;
  }
}
