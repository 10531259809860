@import '~/styles/variables';

.auth-layout {
  color: $white;
  background-color: $secondary-dark;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  min-width: 100vw;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: $huge 0;

  .auth-content {
    width: 90%;
    max-width: 550px;
    padding: $huger $large;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: $smaller;
    background-color: $secondary;
    box-shadow: 0px 6px 12px $secondary-dark;

    .auth-logo {
      max-width: 150px;
      width: 50%;
      margin-bottom: $huger;
    }
  }
}
