@import '~/styles/variables';

.add-item-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 397px;
  border: 1px $gray dashed;
  margin: $medium 0;
}
