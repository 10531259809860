@import '~/styles/variables';

.auth-title {
  font-size: $larger;
  font-weight: bold;
  margin-bottom: $large;
}

.auth-form {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
}

.auth-subaction {
  margin-top: $largest;
  font-size: $medium;

  a {
    color: $secondary-light;
    text-decoration: underline;
    transition: all .3s !important;

    &:hover {
      text-decoration: none;
      color: $primary;
    }
  }
}
