@import '~/styles/variables';

.side-content-overlay {
  pointer-events: none;
  z-index: 1046;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(14, 26, 78, .6);
  opacity: 0;
  transition: all .5s;

  &.opened {
    pointer-events: all !important;
    opacity: 1;
  }
}

.side-content-wrapper {
  pointer-events: none;
  z-index: 1047;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 900px;
  margin-right: -900px;
  overflow-y: auto;
  background-color: $white;
  transition: all .5s;

  &.opened {
    pointer-events: inherit !important;
    right: 900px;
    transition: all .5s;
  }

  &.large {
    width: 1100px !important;
    right: 900px !important;
  }

  & .side-close {
    z-index: 9998;
    margin: $medium $large;
    padding: $medium $large;
    cursor: pointer;
    transition: all .3s;

    &:hover {
      opacity: .6;
    }
  }

  .side-content {
    padding: $medium $largest $hugest;
  }
}
