@import '~/styles/variables';

.page-title {
  display: flex;
  flex-direction: row;
  margin-bottom: $normal;

  h1 {
    margin-left: $smaller;
    font-size: $larger;
    font-weight: bold;
  }
}
