@import '~/styles/variables';

.card-count {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
  border-radius: $smaller;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  padding: $large;
  height: 150px;

  .card-count-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .card-count-footer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }

  .card-title {
    margin-left: $smaller;
    font-size: $normal;
    font-weight: bold;
    color: $primary;
    padding: 0;
    margin: 0;
  }

  .card-value {
    padding: 0;
    margin: 0;
    font-size: $huge;
    font-weight: bold;

    small {
      font-size: $larger;
    }
  }

  .card-link {
    padding: 0 0 10px;
    color: $gray-dark;
    font-size: $medium;
  }
}
