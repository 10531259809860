@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

@import '~/styles/variables.scss';

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

html,
body {
  overflow-x: hidden;
  min-width: 1100px;
  min-height: 100%;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
}

#root {
  width: 100%;
  margin: 0 auto;
}

a,
a:hover,
a:active,
a:visited {
  color: inherit;
  text-decoration: inherit;
  transition: all .3s;
}

a:hover {
  opacity: .6;
}

input {
  border: none !important;
}

img {
  pointer-events: none;
}

.link {
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  cursor: inherit;
}

.form-control:focus {
  border-color: inherit;
  box-shadow: none;
}

.sr-only {
  display: none;
}

@each $color, $value in $colors {
  .text-#{$color} {
    color: $value !important;
  }
}

@each $size, $value in $metrics {
  .text-#{$size} {
    font-size: $value !important;
  }
}
