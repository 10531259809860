@import '~/styles/variables';

.tabs {
  display: flex;
  margin: $small 0 $largest;

  .tab-item {
    color: $gray-dark;
    padding: $small $large $smallest;
    border-bottom: 3px solid $gray;
    cursor: pointer;
    margin: 0 $smallest;

    &.tab-active {
      font-weight: bold;
      color: $info;
      border-bottom: 3px solid $info;
      pointer-events: none !important;
    }
  }
}
