@import '~/styles/variables';

.gift-detail {
  .preview-image {
    margin-top: -90px;
    margin-left: -$largest;
    margin-right: -$largest;
    margin-bottom: $largest;

    img {
      width: 100%;
      height: 400px;
      object-fit: cover;
    }
  }

  .gift {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $medium;

    .gift-detail {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .gift-name {
      margin: 0;
      font-weight: bold;
    }

    .gift-action {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $gray-dark;
    }
  }

  .input-file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border: 2px dashed $gray;
    border-radius: $minimal;
    font-size: $normal;
    color: $black;
    padding: 7px;
    margin-top: 30px;
    cursor: pointer;

    div {
      height: 19px;
      width: calc(100% - 30px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg {
      height: $large !important;
    }

    &::before {
      content: 'Foto';
      position: absolute;
      margin-top: -60px;
      margin-left: -8px;
      font-size: $medium;
    }
  }
}
