@import '~/styles/variables';

.help-detail {
  .user {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $medium;

    .user-detail {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .user-avatar {
      border-radius: 100%;
      height: 80px;
      width: 80px;
      background-image: linear-gradient($primary, $primary),
                        linear-gradient($white, $white),
                        linear-gradient($secondary, $secondary);
      background-repeat: no-repeat;
      background-size: 49% 49%, 51% 51%, 100% 100%;
      background-position: left top bottom, 0 0 0;
      transform: rotate(-90deg);

      img{
        border-radius: 100%;
        width: 76px;
        height: 76px;
        margin: 2px;
        border: 2px solid $white;
        background-color: $white;
        transform: rotate(90deg);
        object-fit: cover;
      }
    }

    .user-name {
      margin: 0 0 0 $medium;
      font-weight: bold;
      font-size: $larger;
    }

    .user-action {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $gray-dark;
    }
  }

  .badge {
    font-size: .9rem;
    border-radius: $large;

    @each $color, $value in $colors {
      &.badge-#{$color} {
        color: $value !important;
        background-color: rgba($value, .15);
        border: $minimal solid $value;
      }
    }
  }

  .detail-map {
    margin-top: $huge;
    margin-left: -$largest;
    margin-right: -$largest;
    margin-bottom: -$hugest;
    height: 500px;
  }
}
